<!--会员设置-->
<template>
  <div style="padding: 20px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div>
        <span style="font-size: 24px; font-weight: 500; color: #707070"
          >会员设置</span
        >
      </div>
      <div></div>
    </div>

    <el-card shadow="never" style="margin-top: 20px">
      <el-form ref="form" :model="form" label-width="170px">
        <div style="border-bottom: 1px solid #eeeeee">
          <div class="xuanxianglist">
            <div v-for="(item, index) in list" :key="index" class="kuai">
              <div
                class="xuanxianglistactive"
                :class="{ xuanxianglistactive1: index == isShow }"
                @click="activeItem(item, index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div style="margin-top: 20px; text-align: right"></div>
      </el-form>

      <div class="card" v-if="their_type == 'platform'">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">数据列表</div>
          </div>
          <div>
            <el-button
              type="primary"
              @click="Add()"
              v-auths="[`${$config.uniquePrefix}operate:memberSettings:save`]"
              >添加</el-button
            >
          </div>
        </div>

        <div class="card_b">
          <!-- 数据表格 -->
          <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :total="total"
            :headerCellStyle="headerCellStyle"
            :customsFromWhere="where"
            :loading="loading"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange"
          >
            <template v-slot:cz="{ scope }">
              <el-link type="primary" @click="edit(scope.row)">编辑</el-link>
              <el-link type="danger" v-if="scope.row.id!=0" @click="del(scope.row)">删除</el-link>
            </template>
            <template v-slot:rights_label="{ scope }">
               {{scope.row.rights_label}}
            </template>
            <template v-slot:cost_label="{ scope }">
               {{scope.row.cost_label}}
            </template>
            <template v-slot:status="{ scope }">
              <el-link
                :underline="false"
                type="warning"
                v-if="scope.row.status == 0"
                v-auths="[
                  `${$config.uniquePrefix}finance:sharedExtract:status`,
                ]"
                @click="affirm(scope.row)"
                >启用</el-link
              >
              <el-link
                :underline="false"
                type="danger"
                v-else
                v-auths="[
                  `${$config.uniquePrefix}finance:sharedExtract:status`,
                ]"
                @click="affirm(scope.row)"
                >禁用</el-link
              >
            </template>
          </my-table>
        </div>
      </div>
      <sivue v-else></sivue>
    </el-card>
    <el-dialog
      title="会员配置"
      top="5vh"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
      :close-on-click-modal='false'
    >
      <div class="flex-right">
        <edit
          :id="id"
          v-if="dialogVisible == true"
          @updatemite="dislog_close"
        ></edit>
      </div>
    </el-dialog>
    <el-dialog
      title="服务商免费会员配置"
      top="5vh"
      :visible.sync="dialogVisible1"
      width="80%"
      :before-close="handleClose1"
    >
      <div class="flex-right">
        <freedit
          :id="0"
          v-if="dialogVisible1 == true"
          @updatemite="dislog_close1"
        ></freedit>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { huiyuan_list, huiyuan_del, huiyuan_status } from "@/api/operate";
import MyTable from "@/components/MyTable";
import edit from "./components/edit";
import sivue from "./components/siji/index.vue";
import freedit from "./components/freedit.vue";
export default {
  components: {
    MyTable,
    edit,
    sivue,
    freedit,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      id: "",
      List: [],
      // 总条目数
      total: 0,
      form: {
        free: {
          accounting_date: "",
        },
      },
      columns: [
        {
          type: "selection",
          isShow: true,
          // fixed: "left",
        },
        {
          label: "编号",
          prop: "id",
            width: '80',
          isShow: true,
        },
        {
          label: "会员权重",
          prop: "weight",
            width: '100',
          isShow: true,
        },
        {
          label: "会员名称",
          prop: "title",
            width: '150',
          isShow: true,
        },
        {
          label: "短信单价元/条",
          prop: "note_price",
          isShow: true,
        },
        {
          label: "电话单价元/分钟",
          prop: "phone_price",
          isShow: true,
        },
        {
          label: "语音提醒单价元/次",
          prop: "voice_price",
          isShow: true,
        },
        {
          label: "会员权益",
          prop: "rights_label",
          isShow: true,
          slot: "rights_label",
        },
        {
          label: "会员费",
          prop: "cost_label",
          isShow: true,
          slot: "cost_label",
        },
        {
          label: "状态",
          prop: "status",
          isShow: true,
          slot: "status",
        },
        {
          label: "操作",
          prop: "cz",
          isShow: true,
          slot: "cz",
          fixed: "right",
        },
      ],
      //数据
      list: [
        {
          name: "平台会员",
        },
        {
          name: "C端司机会员",
        },
      ],
      isShow: 0,

      their_type: "platform",

      //用户添加
      loading: true,
    };
  },

  mounted() {
    //获取列表
    this.getList();
  },

  methods: {
      // 获取列表
    getList() {
      huiyuan_list(this.where).then((res) => {
        this.total = res.data.count;
        // this.in_hand = res.data.in_hand;
        this.List = res.data.list;
        this.loading = false;
      });
      // console.log("666666");
      //   shared_extract(this.where)
      //     .then(async (res) => {
      //       //   console.log(res.data);
      //       this.List = res.data.list;
      //       this.List = [{ status_label: 1 }];
      //       this.loading = false;
      //     })
      //     .catch((res) => {
      //       this.$Message.error(res.msg);
      //       this.loading = false;
      //     });
    },
    affirm(row) {
      huiyuan_status(row.id).then((res) => {
        if (res.code == 200) {
          this.$Message.success("操作成功");
          this.getList();
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    del(row) {
      huiyuan_del(row.id).then((res) => {
        if (res.code == 200) {
          this.$Message.success("操作成功");
          this.getList();
        } else {
          this.$Message.error(res.msg);
        }
      });
    },

    //选择类型
    activeItem(item, index) {
      this.isShow = index;
      if (this.isShow === 0) {
        this.their_type = "platform";
      } else {
        this.their_type = "C";
      }
    },
    //编辑
    edit(row) {
      console.log(row.id,row.id==0)
      if (row.id == 0) {
        this.id = Number(row.id);
        this.dialogVisible1 = true;
      } else {
        this.id = Number(row.id);
        this.dialogVisible = true;
      }

      console.log(row);
    },
    handleClose() {
      this.id = 0;
      this.dialogVisible = false;
    },
    handleClose1() {
      this.id = 0;
      this.dialogVisible1 = false;
    },
    dislog_close() {
      this.id = 0;
      this.dialogVisible = false;
      this.getList();
    },

    dislog_close1() {
      this.id = 0;
      this.dialogVisible1 = false;
      this.getList();
    },
    //添加
    Add() {
      this.id = 0;
      // console.log(this.form1);
      this.dialogVisible = true;
    },

    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.xuanxianglist {
  .kuai {
    margin: 10px;
    display: inline-block;
  }
  .xuanxianglistactive {
    cursor: pointer;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  .xuanxianglistactive:hover {
    background: #ff9b05;
    border: 1px solid #ff9b05;
    border-radius: 6px;
    color: #ffffff;
  }
  .xuanxianglistactive1 {
    background: #ff9b05;
    border: 1px solid #ff9b05;
    border-radius: 6px;
    color: #ffffff;
  }
  .flex-right {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
